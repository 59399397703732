import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseAuthService {

  constructor(
    private auth0: AuthService
  ) { }

  monitorAuthenticationStatus(): void {
    this.auth0.idTokenClaims$.pipe(
      tap((response) => {
        if (response?.exp) {
          this.checkAndRenewToken(response.exp);
        }
      })
    ).subscribe();
  }

  checkAndRenewToken(expiryTimestamp: number): void {
    const isTokenExpired: boolean = this.isTokenExpired(expiryTimestamp);

    console.log('token', isTokenExpired);

    if (isTokenExpired) {
      this.auth0.logout();
    }
  }

  private isTokenExpired(expirationTime: number): boolean {
    const decodedToken: number = expirationTime
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken < currentTime;
  }
}
