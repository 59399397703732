import { AuthGuard } from '@auth0/auth0-angular';
import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./modules/login/login.component').then(mod => mod.LoginComponent),
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadComponent: () => import('./modules/home/home.component').then(mod => mod.HomeComponent),
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboards/dashboards.module').then(m => m.DashboardsModule),
        // pathMatch: 'full',
        canActivate: [AuthGuard]
    }
];
