import { Router, RouterOutlet } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from "./shared/header/header.component";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent],
  template: `
    <ng-container *ngIf="shouldShowHeader(); else noHeader">
      <app-header>
        <router-outlet></router-outlet>
      </app-header>
    </ng-container>
    <ng-template #noHeader>
      <router-outlet></router-outlet>
    </ng-template>
  `,
})
export class AppComponent implements OnInit {
  title = 'Parceiros - RM Farma';

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    console.log('PRODUCTION:', environment.PRODUCTION);
  }

  shouldShowHeader(): boolean {
    return this.router.url !== `/`;
  }
}
